// ctrl + k + 0 to collapse all
export const translations = {
    en: {
        global: {
            loading: "Loading",
            description: "Description",
            fleetHub: "Fleet Hub",
            system: "System",
            serviceReport: "Service Report Form",
            name: "Name",
            yes: "YES",
            no: "NO",
            or: "OR",
            noValue: "-",
            hour: "h",
            empty: "No items",
            downloadHeader: "Attachment",
            signedBy: "Signed by",
            tooltip: {
                edit: "Edit",
                delete: "Delete",
                download: "Download"
            },
            multiMedia: "Multimedia",
            button: {
                create: "Create",
                cancel: "Cancel",
                save: "Save",
                clear: "Clear",
                sign: "Sign",
                delete: "Delete",
                update: "Update",
                edit: "Edit",
                close: "Close",
                yes: "Yes",
                no: "No",
                restore: "Restore default",
                freeze: "Stick",
                unfreeze: "Unstick",
                confirm: "Confirm",
                add: "Add",
                home: "Home",
                back: "Back",
                apply: "Apply",
                send: "Send",
                photosAndVideos: "Photos and videos",
                saving: "Saving..",
                saved: "Saved",
                syncFromMonitor: "Sync from Monitor",
                invite: "Send invite",
                disable: "Disable",
                activate: "Activate"
            },
            validations: {
                required: "The field is required",
                requiredIf: "The field is required",
                minLength: "The field has a min length of {min}", //"The '{property}' field has a min length of {min}",
                maxLength: "The field has a max length of {max}",
                email: "The field has incorrect email format",
                integer: "The field is numeric",
                minValue: "The field has a value of '{model}', but it must have a min value of {min}",
                maxValue: "The field has a value of '{model}', but it must have a max value of {max}",
                decimal: "The field should contain dot or numeric",
                login: "Incorrect credentials",
                userUnique: "The email value must be unique and not used for another users",
                operatorUnique: "The operator already exists"
            },
            confirmations: {
                header: "Confirmation",
                message: "Are you sure you want to delete this item?",
                disable: "Are you sure you want to disable this user?",
                activate: "Are you sure you want to activate this user?",
                cannotDelete: "Cannot delete",
                deleteAutomaticEvent: "In order to delete this event, " +
                  "the connected service will also have to be removed. This action will delete the event and the following service:",
            },
            leaveFeedback: "Leave feedback",
            dateFormats: {
                yearOnly: "YYYY",
                date: "YYYY/MM/DD"
            }
        },
        enums: {
            Incident: "Incident",
            Service: "Service",
            ServiceReport: "Service Report",
            CustomerContact: "Customer contact",
            None: "None",
            Open: "Open (unassigned)",
            InProgress: "Open (in progress)",
            ServicePerformed: "Open (service performed)",
            Resolved: "Closed (resolved)",
            Bug: "Bug",
            PerformanceIssue: "Performance Issue",
            Suggestion: "Suggestion",
            WorkflowImprovement: "Workflow Improvement",
            GeneralFeedback: "General Feedback",
            DataError: "Data Error",
            PartsDataIssue: "Parts Data Issue",
            Low: "Low",
            Medium: "Medium",
            High: "High",
            Critical: "Critical"
        },
        placeholder: {
            serviceSet: "Select templates"
        },
        login: {
            label: {
                signIn: "Sign in",
                email: "Email address",
                password: "Password",
                staySignedIn: "Stay signed in"
            },
            button: {
                login: "Sign in"
            }
        },
        serviceProviders: {
            code: "Code",
            name: "Name",
            country: "Country",
            region: "Region",
            edit: "Edit service provider"
        },
        fleet: {
            synchronize: "Synchronize",
            loader: {
                loadingVessels: "Loading vessels, please wait...",
                loadingVesselsFailed: "Loading vessels failed."
            },
            table: {
                titleFleetList: "Fleet list",
                titleFleetServiceList: "Service report forms",
                columns: {
                    vesselName: "Vessel name",
                    vesselId: "Vessel ID",
                    jetType: "Jet type",
                    serviceProviderName: "Service agent",
                    warranty: "Warranty",
                    warrantyExpiration: "Warranty",
                    showVessel: "Show vessel",
                    subShowVessel: "Go to selected vessel",
                    showReportForm: "Show report form",
                    nextServiceDate: "Planned date",
                    responsibleMJP: "MJP responsible",
                    responsibleSP: "Responsible SP user",
                    mjpId: "MJP ID",
                    jetSize: "Jet size",
                    config: "Config",
                    shipYardCountry: "Builder country",
                    builder: "Builder",
                    edit: "Edit",
                    delete: "Delete",
                    commissionDate: "Commission date",
                    vesselStatus: "Vessel status",
                    controlSystemType: "Control system type",
                    annualRunningHours: "Annual running hours",
                    operatorName: "Operator name",
                    operatorCountry: "Operator country",
                    operatorRegion: "Operator region",
                    warrantyExpirationDate: "Warranty expiration",
                    ownershipType: "Ownership type",
                    customerSegment: "Customer segment",
                    customerSubSegment: "Customer subsegment",
                    application: "Application",
                    hullType: "Hull type",
                    hullNumber: "Hull number",
                    shaft: "Power-shaft (kw)",
                    speed: "Speed",
                    length: "Length",
                    engineMaker: "Engine maker",
                    engineModel: "Engine model",
                    gearBoxMake: "GearBox make",
                    gearBoxRatio: "GearBox ratio",
                    hullMaterial: "Hull material",
                    intakeMaterial: "Intake material",
                    grid: "Grid",
                    imoNo: "IMO No.",
                    mmsi: "MMSI",
                    previousVesselName1: "Previous name 1",
                    previousVesselName2: "Previous name 2",
                    shipYardRegion: "Builder region",
                    comment: "Comment",
                    orderNumber: "Order number",
                    goodsLabel: "Goods label",
                    offers: "Offers"
                },
                warrantyExpired: "Expired",
                warrantyActive: "Active",
                groupBy: "Group by",
                search: "Search"
            },
            addVessel: "Add vessel",
            editVessel: "Edit vessel",
            irrelevantVessels: "Show decommissioned",
            userVessels: "Show my vessels",
            export: "Export",
            deleteVesselHeader: "Warning!",
            deleteVesselText: {
                row1: "This will remove the vessel __vesselNumber__ from the database.This action is irreversible.",
                row2: "Are you certain you want to continue?"
            },
            updateVessel: "Update vessel",
            createEventLog: "Add new event",
            addTemplateSet: "Add template set",
            addOffers: "Add offers",
            bulkUpdate: {
                title: "Bulk update vessel(s)",
                changeTo: "Change to",
                remove: "Remove",
                addParameter: "Add field",
                serviceProvider: "Service agent",
                mjpResponsible: "MJP responsible",
                comment: "Append comment",
                notUniq: "Please remove duplicate fields",
                operator: "Operator",
                country: "Operator country"
            }
        },
        navbar: {
            home: "Home",
            events: "Events",
            fleet: "Fleet",
            serviceReport: "Services",
            vessels: "Vessels",
            admin: "Admin",
            logout: "Logout",
            serviceProviders: "Service agents",
            agent: "Service provider overview"
        },
        eventLog: {
            loader: {
                loadingEventLog: "Loading event log, please wait...",
                loadingEventLogFailed: "Loading event log failed."
            },
            multiMedia: {
                header: "Event details"
            },
            table: {
                titleEventLogList: "Event log",
                columns: {
                    title: "Title",
                    date: "Date",
                    time: "Time",
                    added: "Added",
                    type: "Type",
                    status: "Status",
                    description: "Description",
                    automatic: "Automatic",
                    vesselName: "Vessel name",
                    vesselNumber: "MJP ID",
                    showEdit: "Show/Edit",
                    assignedTo: "Assigned to"
                },
                eventType0: "None",
                bulkAction: "Bulk action",
                groupBy: "Group by",
                exportToExcel: "Export to Excel",
                showHistory: "Include resolved events"
            },
            create: {
                addEventLog: "Add event",
                editEventLog: "Edit event",
                title: "Title",
                status: "Status",
                type: "Type",
                connectedEvents: "Connected events",
                connectedServices: "Connected services",
                description: "Comment",
                image: "Add photo",
                handler: "Assigned handler",
                generateService: "Generate service",
                saveEventLog: "Bulk saving event logs",
                savingEventLog: "Bulk saving event logs",
                savingService: "Bulk saving services",
                serviceInformation: "Service information",
                serviceName: "Service name",
                unassignedHandlerError: "Status open (in progress) requires an assigned handler",
                necessity: "Necessity"
            },
            bulkActions: {
                updateHandler: "Update handler",
                exportToExcel: "Export to excel"
            },
            bulkUpdate: {
                title: "Reassign to new handler"
            }
        },
        vessel: {
            synchronizeInProgress: "Synchronization is in progress. Refresh this page in a few minutes.",
            goToVessel: "Go to vessel",
            multiMedia: {
              header: "Vessel details",
              primary: "Set as primary"
            },
            attachment: {
                files: "Files",
                filesDocuments: "Files & drawings",
                uploadPrivateFile: "Upload MJP only",
                uploadPrivateFileShort: "MJP only",
                uploadPublicFile: "Upload public",
                uploadPublicFileShort: "Public",
                fileName: "File name",
                lastUpdate: "Last update",
                added: "Added by",
                download: "Download",
                preview: "Preview",
                public: "Is Public",
                type: "Type",
                allowedFiles: "Allowed files",
                uploadFilesRestrictions:
                    "Max file size: 100Mb. Only the following file types are allowed: doc, docx, pdf, rtf, xls, xlsx, mpg, wav, bmp, gif, jpg, png, svg, mp4, txt, mp3, mov, avi",
                uploadMultiMediaRestrictions: "Max file size: 100Mb. Only the following file types are allowed: mpg, wav, gif, jpg, png, svg, mp4, mp3, mov, avi",
                publicLabel: "Public",
                mjpOnly: "Mjp only"
            },
            showInfo: "Show vessel info",
            subMenu: {
                back: "Back",
                top: "Top",
                eventLog: "Events",
                schedule: "Services",
                files: "Files",
                profile: "Profiles",
                softwareVersion: "Software",
                sparePartOrders: "Orders",
                part: "Parts",
                expandAdditionalInformation: "Expand for additional information",
                collapse: "Collapse",
                serviceKits: "Kits"
            },
            field: {
                contextMenu: "Context menu",
                annualRunningHours: "Annual running hours",
                vessel: "Vessel",
                vesselNumber: "MJP vessel number",
                jet: "Water Jet",
                controlSystem: "Control System",
                country: "Country",
                hydraulics: " Hydraulics",
                commissionDate: "Date of commissioning",
                vesselStatus: "Vessel status",
                controlSystemType: "Control system type",
                client: "Operator information",
                currentOperator: "Operator",
                region: "Region",
                previousOperators: "Previous operators",
                serviceProvider: "Service agent",
                warrantyStatus: "Warranty Status",
                warrantyExpirationDate: "Warranty expiration date",
                warrantyExpired: "Expired",
                warrantyActive: "Active",
                company: "Company",
                dockingDate: "Docking date time",
                shipYard: "Shipyard",
                image: "Vessel image",
                notificationPeriod: "Notification period",
                additionalVesselInfo: "Additional vessel info",
                ownershipType: "Commercial or gov",
                customerSegment: "Customer segment",
                customerSubSegment: "Subsegment",
                application: "Application",
                hullType: "Hull type",
                hullNumber: "Hull no.",
                shaft: "Power-shaft (kw)",
                speed: "Speed (knots)",
                length: "Length (m)",
                engineMaker: "Engine maker",
                engineModel: "Engine model",
                gearBoxMake: "Gearbox make",
                gearBoxRatio: "Gearbox ratio",
                hullMaterial: "Hull material",
                intakeMaterial: "Intake material",
                grid: "Grid",
                internationalMaritimeOrganizationNumber: "IMO no.",
                maritimeMobileServiceIdentity: "MMSI",
                previousVesselName1: "Prev. vessel name 1",
                previousVesselName2: "Prev. vessel name 2",
                shipyard: "Builder information",
                comment: "Comment",
                classApproval: "Class - Id",
                waterjet: "WaterJet",
                builder: "Builder",
                operator: "Operator",
                serviceShipyard: "Service & builder",
                comments: "Comments",
                aliases: "Aliases",
                concessions: "Concessions",
                currentVesselName: "Current vessel name",
                currentVesselNameHelp: "Click arrow icon to move old name",
                prevVesselName1: "Previous vessel name 1",
                prevVesselName2: "Previous vessel name 2",
                numberUnits: "No. units",
                jetType: "Jet type",
                jetSize: "Jet size",
                config: "Config",
                classId: "Class - Id",
                units: "No. units",
                deliveryYear: "Delivery year",
                deliveryYearInTab: "Delivery year",
                invalidFields: "Check marked tabs for invalid fields",
                addModel: "Add waterjet",
                copyAbove: "Copy above",
                copyAboveShort: "Copy",
                softwareVersion: "Software version",
                noSpSelected: "Select service provider to select this field",
                warrantyEndDate: "Warranty expiration date",
            },
            serviceReport: "Service report",
            commissioningReport: "Commissioning report",
            add: {
                error: {
                    vesselNumber: "ID already exists"
                }
            },
            tab: {
                generalData: "General data",
                nameArrowTooltip: "Clicking the current vessel name arrow will automatically move that name to the previous name field.",
                vesselSpecifications: "Vessel specifications",
                specificationsTooltip: "IMO and MMSI numbers are automatically linked to",
                connectedUsers: "Connected users",
                connectedUsersTooltip: "List of assigned people responsible for the vessel"
            }
        },
        table: {
            exportToExcel: "Export to Excel",
            bulkUpdateEmpty: "Please select items to use bulk update option",
            paginationMessage: "Showing {first} to {last} of {totalRecords}",
            search: "Search",
            bulkAction: "Bulk action",
            groupBy: "Group by",
            expandAll: "Expand All",
            collapseAll: "Collapse All",
            expandAllShort: "Expand",
            collapseAllShort: "Collapse",
            editColumns: {
                tooltip: "Edit columns settings",
                header: "Customize columns",
                regular: "Dynamic",
                frozen: "Sticky"
            }
        },
        service: {
            addHeader: "Add header",
            manage: "Manage",
            primaryTooltip: "Set employee as primary.",
            secondaryTooltip: "Set employee as secondary.",
            attachEventLogs: {
                header: "Attach events to service",
                viewHeader: "Task event logs",
                status: "Status",
                type: "Type",
                assignedHandler: "Assigned handler"
            },
            multiMedia: {
                header: "Task attachments",
                qrExpired: "QR code is valid for 30 min",
                list: "Attached files",
                uploadPhoto: "Upload",
                uploadOrTakeMedia: "Upload/Take",
                empty: "There is nothing here. If you recently attached something, try refreshing the page using the refresh icon above.",
                small: "small size",
                middle: "medium size",
                large: "large size",
                refresh: "Refresh",
                uploadLogo: "Upload logo"
            },
            serviceTaskMultiMedia: "Service task details",
            commentMultiMedia: {
                header: "Comment details"
            },
            serviceDelete: {
                header: "Confirmation",
                body: "Are you sure you want to delete?",
                yes: "Yes",
                no: "No"
            },
            statuses: {
                status0: "None",
                status1: "Tentative",
                status2: "Planned",
                status3: "Service performed",
                status4: "Completed",
                status5: "Confirmed"
            },
            templateList: {
                template900: "12 months",
                template901: "24 months",
                template902: "48 months",
                template903: "5000 hours"
            },
            templatesName: {
                customTemplate: "Custom task"
            },
            types: {
                type0: "None",
                type1: "Required",
                type2: "Recommended"
            },
            commentData: {
                open: "Comment",
                mjpsp: "MJP/SP comment",
                mjp: "MJP only"
            },
            schedule: "SERVICES",
            scheduleLowerCase: "Services",
            setTemplate: "Set of templates",
            setTemplateShort: "Set",
            addService: "Add empty service",
            addServiceShort: "Service",
            addTask: "Add task",
            editTask: "Edit task",
            warrantyClaim: "Warranty claim",
            category: {
                hydraulics: "Hydraulics",
                lubrication: "Lubrication",
                mechanic: "Mechanic",
                control: "Control",
                Remote: "Remote Support"
            },
            menu: {
                update: "Update",
                cancel: "Cancel",
                edit: "Edit"
            },
            taskHeader: "Task",
            sectionHeader: "Section",
            templateHeader: "Template",
            type: "Service type",
            necessity: "Necessity",
            assignTo: "Assign to",
            status: "Service status",
            provider: "Service provider",
            template: "Service template",
            scheduleDate: "Service date",
            task: "Task name",
            comment: "Instruction",
            remark: "Comment",
            customTaskPart: "Add custom component",
            vesselHeader: "Vessel",
            performedBy: "Performed by",
            port: "Port",
            starboard: "Starboard",
            center: "Center",
            inner: "(inner)",
            outer: "(outer)",
            customTaskAction: "Add custom task",
            added: "Added",            
            taskCategory: "Category",
            taskSection: "Section",
            taskCategoryReport: "Category",
            templateReport: "Template",
            taskStatus: "Completed",
            tasks: "Tasks",
            partName: "Component name",
            repeatable: "Repeatable",
            templates: "Service templates",
            serviceTasks: "Service tasks",
            servicePerformed: "Service performed",
            updateService: "Update",
            completeService: "Complete service",
            completeServiceMobile: "Complete",
            saveServiceInfo: "If you cannot complete a task, then leave a comment for said task.",
            serviceSubmitChanges: "Submit changes",
            generatePDF: "Generate PDF",
            description: "Description",
            reportEndDate: "Service date",
            printService: "Print service",
            partIsInvalid: "Component is not defined",
            editService: "Edit service",
            recommendedDateService: "Service date",
            print: "Print service report",
            notApplicable: "N/A",
            dockingDateConfirmed: "Docking date confirmed",
            serviceProviderConfirmed: "Service agent confirmed",
            sparePartsSent: "Spare parts sent",
            sparePartsByOtherParty: "Spare parts by other party",
            isEmpty: "Create an empty service",
            commentUpdatedSuccess: "Comment is updated successfully",
            serviceReportButton: "Service report",
            serviceFormButton: "Open service form",
            oneMore: "One more",
            name: "Name",
            taskExist: "Such task already exists",
            printPage: {
                forPrinting: "For printing: you need to select the available printer in 'Destination'",
                forSaving: "For saving: you need to select 'Save as PDF' in 'Destination'",
                printImages: "Print images",
                goToReport: "Go to Service Report",
                emptyTable: "No tasks were completed",
                printComments: "Print MJP/SP comments"
            },
            tooltip: {
                comment: "Comments",
                subComment: "Read or write a comment",
                eventLogsView: "View event logs",
                subEventLogsView: "View any connected events",
                eventLogsManage: "Manage event logs",
                subEventLogsManage: "Connect an event to this task",
                attachments: "Photos and videos",
                subAttachments: "Add or view photos or videos",
                remove: "Delete",
                technicianComments: "Comment/Instruction",
                unlock: "Unlock",
                lock: "Lock"
            },
            part: {
                modifiedParts: "Modified parts",
                date: "Date",
                action: "Action",
                path: "Path",
                description: "Description",
                actionType0: "Added",
                actionType1: "Deleted",
                actionType2: "Replaced"
            },
            savingTaskResult: "Bulk saving tasks",
            attachingEventLogsAndTasks: "Bulk attaching tasks to event logs",
            list: {
                columns: {
                    plannedDate: "Planned date",
                    serviceDate: "Date",
                    vesselName: "Vessel name",
                    vessel: "Vessel",
                    serviceName: "Service name",
                    name: "Name",
                    mjpId: "MJP ID",
                    serviceProvider: "Service agent",
                    location: "Service location",
                    requestedBy: "Requested by",
                    serviceId: "Service id",
                    serviceProviderName: "Service agent",
                    showService: "Show service",
                    openReportForm: "Open service form",
                    serviceReport: "Service report",
                    serviceStatus: "Status",
                    involvedPersonnel: "Involved personnel",
                    assignedTo: "Assigned to",
                    type: "Type",
                    serviceStatuses: {
                        Tentative: "Tentative",
                        Planned: "Planned",
                        ServicePerformed: "Service performed",
                        Completed: "Completed",
                        Confirmed: "Confirmed"
                    }
                },
                dropdownFilterNames: {
                    tentative: "Tentative",
                    planned: "Planned",
                    performed: "Performed",
                    completed: "Completed",
                    all: "All",
                    confirmed: "Confirmed"
                }
            },
            requestedBy: "Service requested by",
            taskResultsCrud: {
                dialogHeader: "Add task(s)",
                template: "Template",
                previewTemplate: "Preview template",
                closePreview: "Close preview"
            },
            setCrud: {
                dialogHeader: "Add template set",
                startingDate: "Initial start date",
                startDateSub: "Select the approximate date from where the first service should start",
                selectTemplate: "Select template",
                serviceProvider: "Service agent",
                serviceProviderSub: "Will be applied to all services in the set, but can be individually changed later"
            },
            header: "Task header",
            unlock: {
                header: "Unlocking service",
                warning: "Before unlocking and making changes to the service, please download the old report and upload it to the files section.",
                proceed: "Proceed",
                download: "Download",
                continue: "Continue"
            }
        },
        operatingProfile: {
            profile: "Operating profile",
            date: "Date",
            runningHours: "Running hours",
            avgAnnual: "Avg. annual (since commission)",
            avgAnnual_3: "Avg. annual (last 3 years)",
            addedBy: "Added by",
            addProfile: "Add reading value",
            editProfile: "Edit reading value",
            loadingProfiles: "Loading profiles, please wait...",
            loadingProfilesFailed: "Loading profiles failed.",
            annualDeviation: "Annual deviation",
            error: "Dates cannot be equal"
        },
        softwareVersion: {
            hw: "HW",
            portSwBuild: "Port SW build",
            starboardSwBuild: "Stbd SW build",
            displaySwBuild: "Display SW build",
            version: "SOFTWARE VERSION",
            versionLowerCase: "Software version",
            fromDate: "From Date",
            dateTo: "To Date",
            avgAnnual: "Avg. annual (since commission)",
            avgAnnual_3: "Avg. annual (last 3 years)",
            addedBy: "Added by",
            addVersion: "Add software version",
            editVersion: "Edit software version",
            loadingVersions: "Loading profiles, please wait...",
            loadingVersionsFailed: "Loading profiles failed.",
            annualDeviation: "Annual deviation",
            controlSystemType: "Control system type",
            hardware: "Hardware",
            portSoftwareBuild: "Port software build",
            starboardSoftwareBuild: "Starboard software build",
            displaySoftwareBuild: "Display software build",
            addToRegister: "Add version to register",
            comment: "Comment",
            updated: "Updated"
        },
        toast: {
            serviceUpdate: "Service is updated",
            updateSuccess: "Update successful",
            eventLogUpdate: "Event log is updated",
            attachmentsUpdate: "Attachments are updated",
            error: "Error",
            genericErrorMessage: "Something went wrong",
            warn: "Warning",
            wait: "Please wait",
            saved: "Saved",
            thanks: "Thanks!",
            success: "Success",
            deleted: "Deleted successfully",
            commentExceedsCharLength: "The comment is too long. 5000 characters is the allowed limit.",
            fiveThousandLimit: "The maximum character length allowed is 5000",
            successfulRegistration: "your account was successfully created. You'll be redirected in a few seconds...",
            ifNotClickTheLink: "If you're not redirected, click the link below:",
            userSkillError: "Something went wrong when updating the user's skills"
        },
        part: {
            table: {
                titlePartList: "Part list",
                showReplaced: "Show replaced only",
                columns: {
                    partNumber: "Part no",
                    partDescription: "Part Description",
                    quantity: "Per unit",
                    position: "Position",
                    total: "Total",
                    status: "",
                    installDate: "Install date",
                },
                menu: {
                    change: "Change",
                    updatePosition: "Update",
                    updatePositionSub: "Update install date or position",
                    changeSubLabel: "Exchange this part with a new one using the same article number",
                    addTopLevel: "Add part to the top level",
                    addTopLevelSubLabel: "Add a new part with a new article number to the top (first) level",
                    addChild: "Add child part",
                    addChildSubLabel: "Add a new part with a new article number as a child to this one",
                    delete: "Delete",
                    deleteWithChildrenConfirmation:
                        "Warning! This action will delete part {part} and all of its children.",
                    deleteConfirmation: "Warning! This action will delete part {part}."
                }
            },
            synchronize: "Synchronize list",
            synchronizeWait: "Synchronization is started, please wait several minutes until it's done",
            confirmation:
                "This action will synchronize and overwrite the entire list of parts and descriptions, across all vessels",
            syncHistory: "Last sync",
            change: {
                changeTitle: "Change",
                changeSubTitle: "Exchange {name} with a new one using the same article number",
                installDate: "Install date"
            },
            addPart: {
                addTopTitle: "Add part to the top level",
                addTopSubTitle: "Add a new part with a new article number to the top level",
                addChildTitle: "Add part as child",
                addChildSubTitle: "Add a new part with a new article number as a child to {name}",
                installDate: "Install date",
                perPart: "Per unit",
                position: "Position"
            },
            addNew: "Add part as child",
            goToChildren: "Go To Children"
        },
        contact: {
            listHeader: "Contacts",
            serviceProvider: "Service agent",
            adhocServiceProvider: "Adhoc service agent",
            mjpServiceProvider: "MJP Service agent",
            undefined: "Undefined",
            name: "Company name",
            categoryType: "Type",
            category: "Category",
            synchronize: "Synchronize list",
            sync: "Sync",
            synchronizeWait: "Synchronization is started, please wait several minutes until it's done",
            confirmation: "This action will synchronize and overwrite all contacts",
            syncHistory: "Last sync"
        },
        admin: {
            users: "Users",
            contactList: "Contacts",
            operatorList: "Operators",
            operator: {
                create: "Create operator",
                edit: "Edit operator",
                name: "Name"
            },
            templates: "Templates",
            template: {
                create: "Create template",
                edit: "Edit template",
                name: "Template name",
                category: "Category",
                section: "Section",
                tags: "Template tags",
                addTagPlaceholder: "Add tag",
                clearList: "Clear list",
                columns: {
                    template: "Template",
                    concatenatedTags: "Tags"
                }
            },
            singleTemplate: "Single template",
            setOfTemplates: {
                name: "Set of templates",
                setName: "Set name",
                create: "Create template set",
                addRow: "Add row",
                columns: {
                    set: "Set"
                }
            },
            taskList: "Tasks",
            task: {
                create: "Create task",
                edit: "Edit task",
                columns: {
                    category: "Category",
                    section: "Section",
                    task: "Task name",
                    comment: "Predefined instruction",
                    completionType: "Type of input",
                    completionLevel: "Scope"
                }
            },
            software: {
                parentTab: "Software",
                mcuHardwareBuilds: "HW (MCU)",
                displayHardwareBuilds: "HW (Display)",
                mcuSoftwareBuilds: "SW (MCU)",
                displaySoftwareBuilds: "SW (Display)",
                build: "Build",
                controlSystemType: "Control system type",
                hardwareBuildCrudHeader: "Hardware build",
                softwareBuildCrudHeader: "Software build",
                mcuHardwareInputLabel: "Hardware (MCU)",
                displayHardwareInputLabel: "Hardware (Display)",
                mcuSoftwareInputLabel: "Software (MCU)",
                displaySoftwareInputLabel: "Software (Display)",
                controlSystemTypeLabel: "Control system type"
            },
            partsImport: {
                tabHeader: "Parts list",
                customerOrders: "Customer order(s)",
                manufacturingOrders: "Manufacturing order(s)",
                orderContent: "Order content",
                next: "Next",
                prev: "Previous",
                targetVessel: "Target vessel",
                orderNumber: "Order number",
                projectCode: "Project code",
                partNumber: "Part number",
                description: "Description",
                quantity: "Quantity",
                search: "Search",
                topPart: "Top level part",
                import: "Import",
                type: "Type",
                manufactured: "Manufactured",
                purchased: "Purchased",
                part: "Part",
                totalQuantity: "Total quantity",
                index: "Pos no",
                inclusionReason: "Info",
                vesselContainsParts: "This vessel already has a part list",
                syncInfo: "Paste in a list of new line separated part numbers e.g.",
                revisionNumber: "Revision",
                drawingNumber: "Drawing"
            },
            serviceKit: {
                partNumber: "Part no",
                desc: "Description",
                configurations: "Configurations",
                connectedTasks: "Connected tasks",
                tabHeader: "Service kits",
                metaData: "Meta data",
                tasks: "Tasks",
                tagInstruction: "Use a + sign to combine two tags. E.g. CSU+450 will make sure that vessels containing both model CSU and size 450.",
                exclude: "Exclude from new vessels",
                model: "Model",
                size: "Size",
                vessels: "Vessels with this kit",
                statusInfo: "Only vessels with the status \"new\" can be altered"
            },
            offering: {
                tabHeader: "Offerings",
                codeDesc: "Code description",
                partNumber: "Part no",
                desc: "Description",
                additionalInformation: "Additional information",
                exceedsCharLength: "Too many characters"
            }
        },
        user: {
            fullName: "Name",
            serviceProvider: "Organization",
            email: "Email",
            active: "Active",
            addUser: "Add user",
            editUser: "Edit user",
            firstName: "First name",
            lastName: "Last name",
            isAdmin: "Can access admin menu",
            password: "Password",
            confirmPassword: "Confirm password",
            canAccessAdmin: "Can access admin menu",
            userRoleChanged: "User admin role is changed",
            userName: "Username",
            createAccount: "Create account",
            passwordNotMatching: "Your passwords are not identical",
            registrationExpired: "The registration link has expired!",
            emailUnavailable: "The email is unavailable. Someone else might have registered with it.",
            forgotPassword: "Forgot password",
            back: "Back",
            send: "Send",
            passwordResetExpired: "The password reset link has expired!",
            loginFailed: "Login failed, incorrect username or password",
            inviteSent: "Invitation has been sent",
            passwordResetSent: "Password reset sent",
            deleteSelfWarn: "You cannot delete your own account",
            passwordReset: "Password reset",
            newPassword: "New password"
        },
        eventLogHome: {
            showMy: "My assigned events",
            rangeSelector: "Date range",
            table: {
                columns: {
                    showService: "Show service",
                    showEvent: "Show event"
                }
            }
        },
        feedback: {
            addFeedback: "Add feedback",
            type: "Type",
            severity: "Severity",
            comment: "Comment",
            image: "Attach screenshot",
            chooseLabel: "Browse",
            sent: "Sent"
        },
        advancedSearch: {
            title: "Advanced search",
            addQuery: "Add query",
            remove: "Remove query",
            clear: "Clear",
            from: "From",
            to: "To",
            field: "Field",
            fleet: {
                dateOfCommissioning: "Date of commissioning",
                deliveryYear: "Delivery year"
            }
        },
        services: {
            serviceReportForms: "Service report forms",
            serviceList: "Services"
        },
        aliasDialog: {
            header: "Add alias",
            addAlias: "Add"
        },
        serviceTab: {
            tooltip: {
                unableToRemoveSp: "Cannot be removed until all services with this service provider is done"
            }
        },
        sparePartOrder: {
            orderNumber: "Order no",
            goodsLabel: "Goods label",
            orderDate: "Order date",
            status: "Status",
            rowsHeader: "Spare part order",
            prev: "Prev order",
            next: "Next order",
            partNumber: "Part no",
            partDesc: "Part description",
            quantity: "Delivered qty",
            orderedQuantity: "Ordered qty",
            deliveryDate: "Delivery date",
            missingPart: "Missing from this vessel's parts list",
            sync: "Synchronize",
            successToast: "The list is being synchronized. Check back in a minute.",
            desc: "Description",
        },
        audit: {
            dialogHeader: "History log",
            events: "Events",
            date: "Date",
            user: "User",
            action: "Action"
        },
        completionValue: {
            exceedsCharLength: "Only 25 characters allowed",
            done: "Done",
            notDone: "Not done"
        },
        registration: {
            title: "Register"
        },
        unsavedChanges: {
            header: "Unsaved changes",
            warning: "You might have unsaved changes. Are you sure you want to close this windows and discard them?"
        },
        offer: {
            header: "Offers"
        },
        agent: {
            top: "Top",
            events: "Events",
            fleet: "Fleet",
            services: "Services",
            orders: "Orders",
            spCode: "Service agent code",
            agentInfo: "Agent information",
            countries: "Countries",
            servicePerformance: "Service performance",
            employees: "Employees"
        },
        servicePerformance: {
            set: "Set",
            plannedServices: "Planned services",
            last: "Last",
            months: "months",
            moreThanLast: "More than last",
            lessThanPlanned: "Less than planned this period",
            moreThanPlanned: "More than plannedt this period",
            performedServices: "Performed services",
            next: "Next",
            lessThanLast: "Less then last",
            monthPeriod: "month period"
        },
        mobile: {
            prev: "Prev",
            submenu: "Submenu",
            next: "Next",
            menu: "Menu"
        },
        dashboard: {
            title: "Dashboard",
            newEvents: "New events",
            newVessels: "New vessels",
            newServices: "New services",
            servicesPerformed: "Services performed",
            servicesOverdue: "Services overdue",
            aboutToExpire: "Warranties about to expire",
            within2Months: "Within 2 months",
            warrantyExpired: "Warrany expired",
            noValidWarranty: "No valid warranty",
            last: "Last",
            days: "days",
            export: "Export"
        },
        skills: {
            confirmation: "This skill might still be assigned to active users. Are you sure you want to remove it?",
            title: "Skills",
            level: "Level",
            skill: "Skill",
            required: "Req. skill"
        },
        wiki: {
            addSection: "Add section",
            header: "Fleethub help center",
            headerSubtitle: "Have any questions about FleetHub? Look no further. Get up and running with the help of these topics and helpful guides.",
            createSectionHeader: "Create section",
            updateSectionHeader: "Update section",
            addSubSection: "Add sub-section",
            delete: "Delete",
            edit: "Edit",
            exceedsAttachmentLimit: "Max allowed attachments is 8."
        }
    }
};
