import "reflect-metadata";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import "bootstrap";
import { translations } from "./i18n/all";
import store, { key } from "./store";
import { createI18nMessage } from "@vuelidate/validators";
import * as validators from "@vuelidate/validators";
// Components
import NavigationBarControlComponent from "./components/complex.controls/navigation.bar/navigation.bar.control.vue";
import Button from "primevue/button";
import SplitButton from "primevue/splitbutton";
import Chip from "primevue/chip";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import Menu from "primevue/menu";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import Menubar from "primevue/menubar";
import Badge from "primevue/badge";
import Panel from "primevue/panel";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Chips from "primevue/chips";
import Toast from "primevue/toast";
import Password from "primevue/password";
import InputNumber from "primevue/inputnumber";
import MultiSelect from "primevue/multiselect";
import InputSwitch from "primevue/inputswitch";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import TreeTable from "primevue/treetable";
import Accordion from "primevue/accordion";
import OverlayPanel from "primevue/overlaypanel";
import Listbox from "primevue/listbox";
import AccordionTab from "primevue/accordiontab";
import ProgressBar from "primevue/progressbar";
import RadioButton from "primevue/radiobutton";
import DataTableComponent from "./components/base.controls/data.table/data.table.vue";
import ActionMenu from "./components/base.controls/action.menu/action.menu.vue";
import CancelButton from "./components/base.controls/cancel.button/cancel.button.vue";
import ColoredDropdownComponent from "./components/base.controls/colored.dropdown/colored.dropdown.vue";
import ConfirmationDialogComponent from "./components/base.controls/confirmation.dialog/confirmation.dialog.vue";
import NavigationPageComponent from "./components/base.controls/navigation.page/navigation.page.vue";
import ToastComponent from "./components/base.controls/toast/toast.vue";
import TreeTableComponent from "./components/base.controls/tree.table/tree.table.vue";
import FeedbackFormComponent from "./components/complex.controls/feedback.form/feedback.form.vue";
import ProgressSpinner from 'primevue/progressspinner';
import Paginator from "primevue/paginator";
import Slider from 'primevue/slider';
import './utilities/global';

export const i18n = createI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: translations
});

// https://vuelidate-next.netlify.app/validators.html
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const messagePath = (p: any) => `global.validations.${p.$validator ?? p.$params?.type}`;
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n), messagePath });
export const required = withI18nMessage(validators.required);
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true });
export const minLength = withI18nMessage(validators.minLength, { withArguments: true });
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true });
export const minValue = withI18nMessage(validators.minValue, { withArguments: true });
export const maxValue = withI18nMessage(validators.maxValue, { withArguments: true });
export const email = withI18nMessage(validators.email);
export const integer = withI18nMessage(validators.integer);
export const decimal = withI18nMessage(validators.decimal);

const app = createApp(App).use(store, key).use(router).use(PrimeVue).use(i18n).use(ToastService);

app.component("NavigationBar", NavigationBarControlComponent);
app.component("FeedbackForm", FeedbackFormComponent);
app.component("Button", Button);
app.component("SplitButton", SplitButton);
app.component("Dropdown", Dropdown);
app.component("InputText", InputText);
app.component("Checkbox", Checkbox);
app.component("RadioButton", RadioButton);
app.component("Dialog", Dialog);
app.component("Calendar", Calendar);
app.component("Menu", Menu);
app.component("Textarea", Textarea);
app.component("FileUpload", FileUpload);
app.component("Menubar", Menubar);
app.component("Badge", Badge);
app.component("Panel", Panel);
app.component("TabPanel", TabPanel);
app.component("TabView", TabView);
app.component("Chips", Chips);
app.component("Chip", Chip);
app.component("Toast", Toast);
app.component("Password", Password);
app.component("InputNumber", InputNumber);
app.component("MultiSelect", MultiSelect);
app.component("InputSwitch", InputSwitch);
app.component("Column", Column);
app.component("DataTable", DataTable);
app.component("TreeTable", TreeTable);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("DataTableComponent", DataTableComponent);
app.component("ActionMenu", ActionMenu);
app.component("CancelButton", CancelButton);
app.component("ColoredDropdownComponent", ColoredDropdownComponent);
app.component("ConfirmationDialogComponent", ConfirmationDialogComponent);
app.component("NavigationPageComponent", NavigationPageComponent);
app.component("ToastComponent", ToastComponent);
app.component("TreeTableComponent", TreeTableComponent);
app.component("OverlayPanel", OverlayPanel);
app.component("Listbox", Listbox);
app.component("ProgressBar", ProgressBar);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Paginator", Paginator);
app.component("Slider", Slider);

app.mount("#app");
